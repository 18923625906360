.container {
  margin: 0 auto;
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
}

.title {
  font-family: "Libre Franklin", sans-serif;
  color: white;
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
  position: absolute;
  bottom: 0px;
  padding-left: 2em;
  padding-bottom: 20px;
  background: linear-gradient(
    0deg
    , rgba(0,0,0,0.6), transparent 90%);
  width: 100%;
}
.title > a {
  color: white;
  text-decoration: none;
}

.subtitle {
  font-weight: 400;
}

.postHeader {
  background-image: url('../images/Hamblin-Christmas-Photo-2024.jpg');
  background-size: cover;
  background-position: 50% 19%;
  height: 180px;
  position: relative;
}
