@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:400,400i,700,700i,900);
:root {
  --primary-color: #222;
  --primary-color-light: #767676;
}

* {
  box-sizing: border-box;
}

html {
  color: rgba(0, 0, 0, 0.9);
  font: 100%/1.75 'Libre Franklin',"Helvetica Neue", helvetica, arial, sans-serif;
}

body {
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-family: 'Libre Franklin',"Helvetica Neue", helvetica, arial, sans-serif;
  font-feature-settings: "kern", "liga", "clig", "calt", "kern";
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

pre,
code {
  font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New", monospace;
  font-size: 0.85rem;
  line-height: 1.5;
}
pre {
  border-radius: 10px;
  overflow: auto;
  padding: 1em;
}

a {
  color: var(--primary-color);
}
a:hover {
  text-decoration: none;
}

p, pre, ul, ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}
.ArticleMeta_tags__1IJ9w,
.ArticleMeta_tags__1IJ9w li {
  display: inline;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ArticleMeta_tags__1IJ9w li:not(:first-child)::before {
  content: ', ';
}
.ArticleSummary_ArticleSummary__3I5Qk h2 {
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 0;
}

.ArticleSummary_ArticleSummary__3I5Qk a {
  text-decoration: none;
}
.Pagination_Pagination__2Xt2h {
  display: block;
  margin: 4.375rem 0 7rem;
}
  
.Pagination_Pagination__2Xt2h .Pagination_previous__1akUm {
  margin-right: 0.5rem;
}
.Pagination_Pagination__2Xt2h .Pagination_next__7Ba4Y {
  margin-left: 0.5rem;
}
.BlogIndexPage_mainPic__3UVV7 {
  object-fit: cover;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
}

.BlogIndexPage_header__IRcyg {
  height: 100vh;
  position: relative;
  background: linear-gradient(0deg, rgba(0,0,0,0.3), transparent 20%);
}

.BlogIndexPage_content__1SB2m {
  background: white;
}
.BlogIndexPage_container__KI3Qb {
  margin: 0 auto;
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
}

.BlogIndexPage_title__341hM {
  font-family: "Libre Franklin", sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin-top: 0;
  margin-bottom: 2.625rem;
  padding-left: 2em;
  text-rendering: optimizeLegibility;
  position: absolute;
  bottom: 0;
}
.BlogIndexPage_title__341hM a {
  color: white;
  text-decoration: none;
}
.BlogIndexPage_description__6iiVY {
  color: white;
  font-weight: 400;
  font-size: .7em;
}

.BlogIndexPage_articlesList__1V39R,
.BlogIndexPage_articlesList__1V39R > li {
  list-style: none;
  padding: 0;
  margin: 0 0 2.625rem;;
}

.BlogIndexPage_footer__1vkZQ {
  margin-top: 7rem;
  margin-bottom: 0.5rem;
}

.LoadingIndicator_LoadingIndicator__3KSl0 {
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  background-color: var(--primary-color-light);
  background-size: 35px 35px;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  transition: transform ease-in 300ms, opacity ease-in 300ms;
  transition-delay: 0;
  transform-origin: left center;
  transform: scaleX(0);
  opacity: 0;
}

.LoadingIndicator_LoadingIndicator__3KSl0.LoadingIndicator_active__3EncM {
  opacity: 1;
  transition-delay: 333ms;
  animation: LoadingIndicator_LoadingIndicator-animation__2icwd 10s ease-out;
  animation-fill-mode: forwards;
}

@keyframes LoadingIndicator_LoadingIndicator-animation__2icwd {
  0% {
    transform: scaleX(0);
  }
  10% {
    transform: scaleX(0.3);
  }
  50% {
    transform: scaleX(0.7);
  }
  90% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(1);
  }
}
.BlogLayout_container__1fiRb {
  margin: 0 auto;
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
}

.BlogLayout_title__3HyDU {
  font-family: "Libre Franklin", sans-serif;
  color: white;
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
  position: absolute;
  bottom: 0px;
  padding-left: 2em;
  padding-bottom: 20px;
  background: linear-gradient(
    0deg
    , rgba(0,0,0,0.6), transparent 90%);
  width: 100%;
}
.BlogLayout_title__3HyDU > a {
  color: white;
  text-decoration: none;
}

.BlogLayout_subtitle__beGZq {
  font-weight: 400;
}

.BlogLayout_postHeader__x1YoD {
  background-image: url(/static/media/Hamblin-Christmas-Photo-2024.e1b99c30.jpg);
  background-size: cover;
  background-position: 50% 19%;
  height: 180px;
  position: relative;
}

.Bio_Bio__1ks_2 {
  display: flex;
  margin-bottom: 4.375rem;
}
  
.Bio_Bio__1ks_2 img {
  border-radius: 50%;
  flex-shrink: 0;
  height: 3.5rem;
  margin-bottom: 0;
  margin-right: 0.875rem;
  width: 3.5rem;
}
.BlogPostLayout_container__3yuyZ {
  margin: 0 auto;
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
}

.BlogPostLayout_title__2L_2_ {
  font-family: "Libre Franklin", sans-serif;
  font-weight: 900;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
.BlogPostLayout_title__2L_2_ > a {
  text-decoration: none;
}

.BlogPostLayout_header__1Ny4T {
  margin-bottom: 2.4rem;  
}
.BlogPostLayout_header__1Ny4T .BlogPostLayout_title__2L_2_ {
  font-size: 2.5rem;
  margin-top: 1.7rem;
  margin-bottom: 0;
}
.BlogPostLayout_header__1Ny4T .BlogPostLayout_title__2L_2_ > a {
  color: black;
}

.BlogPostLayout_footer__3ofxa {
  margin-top: 7rem;
}
.BlogPostLayout_footer__3ofxa .BlogPostLayout_title__2L_2_ {
  font-size: 1.44rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
}
.BlogPostLayout_footer__3ofxa .BlogPostLayout_title__2L_2_ > a {
  color: var(--primary-color-light);
}

.BlogPostLayout_bio__diNn9 {
  margin-bottom: 7rem;
}

.BlogPostLayout_links__-LX2X {
  display: flex;
  justify-content: space-between;
}
.BlogPostLayout_links__-LX2X .BlogPostLayout_next__KrMHx {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
}

.BlogPostLayout_content__1mzTO hr {
  background: hsla(0,0%,0%,0.2);
  border: none;
  height: 1px;
}

.BlogPostLayout_content__1mzTO h2,
.BlogPostLayout_content__1mzTO h3,
.BlogPostLayout_content__1mzTO h4,
.BlogPostLayout_content__1mzTO h5,
.BlogPostLayout_content__1mzTO h6 {
  font-weight: 900;
  line-height: 1.1;
  margin-top: 3.5rem;
  text-rendering: optimizeLegibility;
}
.BlogPostLayout_content__1mzTO h5 {
  color: #767676;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.15em;
  font-size: 0.8125rem;
}

.BlogPostLayout_content__1mzTO img {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  margin: 30px 0;
}
  
/**
* Based on copypasta from Remy Bach and Sarah Drasner
*/
.BlogPostLayout_content__1mzTO code,
.BlogPostLayout_content__1mzTO pre {
  color: white;
  font-family: Consolas,Menlo,Monaco,source-code-pro,Courier New,monospace;
  font-feature-settings: normal;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  -webkit-hyphens: none;
          hyphens: none;
}
  
/* Code blocks */
.BlogPostLayout_content__1mzTO pre {
  overflow: auto;
  padding: 1em;
}

.BlogPostLayout_content__1mzTO :not(pre) > code,
.BlogPostLayout_content__1mzTO pre {
  background: hsla(0,0%,0%,0.9);
}
  
.BlogPostLayout_content__1mzTO pre::-moz-selection,
.BlogPostLayout_content__1mzTO pre::selection {
  background: hsl(207, 4%, 16%);
}
  
/* Text Selection colour */

.BlogPostLayout_content__1mzTO pre::selection,
.BlogPostLayout_content__1mzTO pre ::selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

/* Inline code */
.BlogPostLayout_content__1mzTO :not(pre) > code {
  border-radius: .3em;
  background: rgba(255,229,100,0.2);
  color: #1a1a1a;
  padding: .15em .2em .05em;
  white-space: normal;
}
.BlogPostLayout_content__1mzTO a > code {
  color: var(--primary-color);
}

.BlogPostLayout_content__1mzTO .highlighted-line {
  background-color: hsla(207, 95%, 15%, 1);
  display: block;
  margin-right: -1em;
  margin-left: -1em;
  padding-right: 1em;
  padding-left: 0.75em;
  border-left: 0.25em solid #ffa7c4;
}

.BlogPostLayout_content__1mzTO .token.attr-name {
  color: rgb(173, 219, 103);
  font-style: italic;
}

.BlogPostLayout_content__1mzTO .token.comment {
  color: rgb(99, 119, 119);
}

.BlogPostLayout_content__1mzTO .token.string,
.BlogPostLayout_content__1mzTO .token.url {
  color: rgb(173, 219, 103);
}

.BlogPostLayout_content__1mzTO .token.variable {
  color: rgb(214, 222, 235);
}

.BlogPostLayout_content__1mzTO .token.number {
  color: rgb(247, 140, 108);
}

.BlogPostLayout_content__1mzTO .token.builtin,
.BlogPostLayout_content__1mzTO .token.char,
.BlogPostLayout_content__1mzTO .token.constant,
.BlogPostLayout_content__1mzTO .token.function {
  color: rgb(130, 170, 255);
}

.BlogPostLayout_content__1mzTO .token.punctuation {
  color: rgb(199, 146, 234);
}

.BlogPostLayout_content__1mzTO .token.selector,
.BlogPostLayout_content__1mzTO .token.doctype {
  color: rgb(199, 146, 234);
  font-style: 'italic';
}

.BlogPostLayout_content__1mzTO .token.class-name {
  color: rgb(255, 203, 139);
}

.BlogPostLayout_content__1mzTO .token.tag,
.BlogPostLayout_content__1mzTO .token.operator,
.BlogPostLayout_content__1mzTO .token.keyword {
  color: #ffa7c4;
}

.BlogPostLayout_content__1mzTO .token.boolean {
  color: rgb(255, 88, 116);
}

.BlogPostLayout_content__1mzTO .token.property {
  color: rgb(128, 203, 196);
}

.BlogPostLayout_content__1mzTO .token.namespace {
  color: rgb(178, 204, 214);
}
